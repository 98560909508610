import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

/** Use this component to show if the password is valid. */

@Component({
  selector: 'zi-password-validator',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './password-validator.component.html',
  styleUrl: './password-validator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordValidatorComponent {
  @Input() isPwLengthCorrect = signal(false);
  @Input() isPwIncludeLowerCaseLetter = signal(false);
  @Input() isPwIncludeNumber = signal(false);
  @Input() isPwIncludeSymbol = signal(false);
}
