
import {of as observableOf, Observable} from 'rxjs';

import {map, catchError, timeout, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LoginConfig} from '../login.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import * as _ from 'lodash';
import { environment } from '../../../environments/environment';

@Injectable()
export class RequestsService {

  constructor(private _http: HttpClient) { }

  /**
   * Regular Login
   *
   * Expect the following body example:
   * {
   *  email: string,
   *  password: string,
   *  isRedirect: boolean,
   *  scopes: Array,
   *  ziversion_cookie: string,
   *  machineId: string,
   *  isUniversity: boolean
   * }
   *
   * **/
  login(body) {
    return this._http.post(LoginConfig.GROW_BACKEND_LOGIN_ENDPOINT, body);
  }

  /**
   * Forgot PW
   *
   * Expect the following body example:
   * {
   *  email: string
   * }
   *
   * **/
  sendForgotPwEmail(body) {
    return this._http.post(LoginConfig.GROW_BACKEND_SEND_UNIFIED_RESET_PASSWORD_EMAIL_ENDPOINT, body);
  }

  /**
   * Reset PW
   *
   * Expect the following body example:
   * {
   *  newPassword: string,
   *  confirmPassword: string,
   *  ziid: string
   * }
   *
   * **/
  resetPassword(body) {
    return this._http.post(LoginConfig.GROW_BACKEND_RESET_PASSWORD_ENDPOINT, body);
  }

  /**
   * Validate reset PW token
   * **/
  validateResetPwToken(resetPwToken, anura, url, resetPwUrl) {
    const body = {anura, url, resetPwUrl, resetPwToken};
    return this._http.post(`${LoginConfig.GROW_BACKEND_RESET_PASSWORD_TOKEN_VALIDATION_ENDPOINT}`, body);
  }

  /**
   * Verify 2FA Code
   *
   * Expect the following body example:
   * {
   *  pinValue: string,
   *  pinId: string,
   *  method: string
   * }
   *
   * **/
  verify2FaCode(body) {
    return this._http.post(LoginConfig.GROW_BACKEND_2FA_VERIFY_ENDPOINT, body);
  }

  /**
   * Resend 2FA Code
   *
   * Expect the following body example:
   * {
   *  pinId: string,
   *  method: string,
   *  phone / email: string
   * }
   *
   * **/
  resend2FaCode(body) {
    return this._http.post(LoginConfig.GROW_BACKEND_SEND_2FA_CODE_ENDPOINT, body);
  }

  /**
   * Start 2FA Totp process
   *
   * Sending the request to GB with the relevant token in order to get the inline QR code image
   *
   * Expect the following body example:
   * {
   *  token: string
   * }
   *
   * **/
  startTotp(token, anura = '', forceAllowAll2faMethods) {
    const body = {token, anura, forceAllowAll2faMethods};
    return this._http.post(LoginConfig.GROW_BACKEND_GET_START_TOTP_ENDPOINT, body);
  }

  /**
   * Verify 2FA Totp code
   *
   * Expect the following body example:
   * {
   *  code: string,
   *  token: string
   * }
   *
   * **/
  verifyTotpCode(body) {
    return this._http.post(LoginConfig.GROW_BACKEND_VERIFY_TOTP_CODE_ENDPOINT, body);
  }

  /**
   * Get General Information from the backend
   *
   * This method will never fail, it's returning a default data in case of failure
   * **/
  getGeneralInfoData() {
    // return this._http.get(LoginConfig.GROW_BACKEND_GENERAL_INFO_DATA_ENDPOINT).pipe(timeout( 5000),
    //   catchError((err) => {
    //     console.log(`Failed getting general information from the backend, respond with default information, Error :: ${JSON.stringify(err, null, 2)}`);
    //     return observableOf({
    //       loginCaptchaNeeded: false,
    //       forgotPasswordCaptchaNeeded: false,
    //       oAuthLoginEnabled: false
    //     });
    //   }),);
    return observableOf({
      loginCaptchaNeeded: false,
      forgotPasswordCaptchaNeeded: false,
      oAuthLoginEnabled: false
    });
  }

  /**
   * Get relevant user's home page from BS with providing:
   * 1. ziid
   * 2. zisession
   * 3. userId
   * 4. parseSessionToken
   *
   * This method will never fail, it's returning a default data in case of failure
   * **/
  getUserDetails(ziid, zisession, userId, parseSessionToken, platform = '', isReachout = false, isOktaLogin = false) {
    const options = {
      headers: {'x-ziid': ziid, 'x-zisession': zisession, 'user': userId, 'session-token': parseSessionToken},
      params: {'refreshUserInfo': 'true', anura: platform, isReachout: isReachout.toString(), isOktaLogin: isOktaLogin.toString()}
    };
    return this._http.get(LoginConfig.GROW_BACKEND_USER_DETAILS_ENDPOINT, options).pipe(
      timeout(5000),
      map((resp) => _.get(resp, 'data', {})),
      catchError((err) => {
        console.log(`Failed getting the user details for the user, ziid :: ${ziid}, zisession :: ${zisession}, Error ::`, err);
        return observableOf(null);
      }));
  }

  /**
   * Login To University
   *
   * This method purpose is to Login to Zoom University, it's purpose is to respond with the relevant university login token.
   *
   * **/
  loginToUniversity(ziid, zisession) {
    const body = {ziid, zisession};
    console.log(`inside loginToUniversity, trying to login user to university, request body :: ${JSON.stringify(body, null, 2)}`);

    return this._http.post(LoginConfig.GROW_BACKEND_LOGIN_TO_UNIVERSITY_ENDPOINT, body).pipe(
      catchError((err) => {
        const errMessage = err instanceof Error ? err : JSON.stringify(err, null, 2);
        console.log(`inside loginUniversity, failed login user to university, ziid :: ${ziid}, err :: ${errMessage}`);
        return observableOf(null);
      }));
  }


  /**
   * Connect User with Token
   * **/
  connectUserWithToken(ziid, zisession, userId, parseSessionToken, token, platform = '') {
    const params = {id: userId, token, anura: platform};
    const headers = {'x-ziid': ziid, 'x-zisession': zisession, 'user': userId, 'session-token': parseSessionToken};
    return this._http.get(LoginConfig.GROW_BACKEND_CONNECT_USER_ENDPOINT, {params, headers});
  }

  /**
   * Connect User with Client ID
   * **/
  connectUserWithClientId(ziid, zisession, userId, parseSessionToken, clientId, platform = '') {
    const body = {client_id: clientId, anura: platform};
    const headers = {'x-ziid': ziid, 'x-zisession': zisession, 'user': userId, 'session-token': parseSessionToken};
    return this._http.post(LoginConfig.GROW_BACKEND_CONNECT_USER_WITH_CLIENT_ID_ENDPOINT, body, {headers});
  }

  /**
   * This request is responsible to check if the request is valid and will return the desired html source.
   * This is got built after facing some blocking for html requests (like iFrame..) from some chrome extensions
   * **/
  checkLoginAd(getHtml) {
    const params = {getHtml};
    return this._http.get(LoginConfig.WEBSITE_BACKEND_LOGIN_ADS_ENDPOINT, {params}).pipe(timeout(3000));
  }

  sendLeadToCRM(body) {
    return this._http.post(LoginConfig.WEBSITE_SEND_LEAD_ENDPOINT, body, { responseType: 'text' }); // Need to set response type text header because the endpoint respond with nothing.
  }

  /**
  * This request is responsible to get user country code and check if its from EU
  * **/
  checkIfClientIsEu() {
    return this._http.get(LoginConfig.WEBSITE_BACKEND_AM_I_EU_ENDPOINT).pipe(
      catchError((err) => {
        console.log(`Failed to check if user is from EU, responding with "true", err: ${JSON.stringify(err)}`);
        return observableOf(true);
      }));
  }

  /**
  * This request is responsible to get user country code
  * **/
  getClientCountry() {
    return this._http.get(LoginConfig.WEBSITE_BACKEND_WHAT_IS_MY_COUNTRY).pipe(
      catchError((err) => {
        console.log(`Failed to check visitors country, responding with empty object, err: ${JSON.stringify(err)}`);
        return observableOf({});
      }));
  }

  /**
   * Toggle user
   * **/
  toggleUser(body) {
    return this._http.post(LoginConfig.LOGIN_BE_TOGGLE_USER_ENDPOINT, body);
  }

  oauthLoginByToken(body) {
    return this._http.post(LoginConfig.LOGIN_BE_OAUTH2_LOGIN_BY_TOKEN_ENDPOINT, body);
  }

  successfulLogin(body) {
    return this._http.post(LoginConfig.LOGIN_BE_SUCCESSFUL_LOGIN, body);
  }

  isDoziReachoutInstalled() {
    return this._http.get(LoginConfig.DOZI_REACHOUT_EXTENSION_URL).pipe(
      map((resp: object) => {
        return observableOf(_.get(resp, 'status') === 200);
      }),
      catchError((resp: object) => {
        return observableOf(_.get(resp, 'status') === 200);
      }));
  }

  getSsoRedirectUrl(accountName) {
    return this._http.post(LoginConfig.SSO_REDIRECT_ENDPOINT, accountName);
  }

  getFactorsFromUms(accountName) {
    return this._http.get<any>(LoginConfig.UMS_PASSWORD_RESET_ENDPOINT + '/' + accountName);
  }

  sendPasswordResetEmailChallenge(body) {
    return this._http.post<any>(LoginConfig.UMS_PASSWORD_RESET_ENDPOINT + '/sendEmailChallengeRequest', body);
  }

  sendPasswordResetSmsChallenge(body) {
    return this._http.post<any>(LoginConfig.UMS_PASSWORD_RESET_ENDPOINT + '/sendSmsChallengeRequest', body);
  }

  verifyPasswordResetChallenge(accountName, passcode, factorType) {
    return this._http.post<any>(LoginConfig.UMS_PASSWORD_RESET_ENDPOINT + '/verifyChallengeRequest/' + 'factorType/' + factorType, {accountName: accountName, passCode: passcode});
  }

  setPassword(factorType, body) {
    return this._http.post(LoginConfig.UMS_PASSWORD_RESET_ENDPOINT + '/factorType/' + factorType, body);
  }

  oktaRevokeUserSessions(keepCurrent: boolean) {
    const oktaUrl = LoginConfig.OKTA_DELETE_SESSIONS_ENDPOINT;
    const oktaHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });

    const body = '{"keepCurrent": ' + keepCurrent + '}';
    console.log(`oktaRevokeUserSessions with body ${body}`);
    return this._http.post(oktaUrl, body, {headers: oktaHeaders, withCredentials: true});
  }

  checkOktaSessionActive(): Observable<any> {
    const oktaUrl = LoginConfig.OKTA_GET_SESSIONS_ME_ENDPOINT;
    const oktaHeaders = new HttpHeaders({
      'Accept': 'application/json'
    });

    console.log(`checkOktaSessionActive calling ${oktaUrl}`);
    return this._http.get(oktaUrl, {headers: oktaHeaders, withCredentials: true, observe: 'response'});
  }

  /** Auto login to ZIL platform. */
  autoProvisioningLogin(body): Observable<any> {
    return this._http.post(environment.sm_auto_provisioning_login_url, body);
  }
}
