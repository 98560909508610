import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotyComponent} from './components/noty/noty.component';
import { CaptchaComponent } from './components/captcha/captcha.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {DialogTemplateComponent} from "./components/dialog-template/dialog-template.component";
import {DialogFooterComponent} from "./components/dialog-template/dialog-footer/dialog-footer.component";
import {DialogContentComponent} from "./components/dialog-template/dialog-content/dialog-content.component";
import { PlatformSelectDialogComponent } from './components/platform-select-dialog/platform-select-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {CommonService} from "./common.service";
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { AnalyticsService } from '../core/services/analytics.service';
import { RegularForgotPasswordComponent } from '../login/screens/regular-forgot-password/regular-forgot-password.component';
import { RegularBackLinkComponent } from '../login/components/regular-back-link/regular-back-link.component';
import { SubmitButtonComponent } from '../login/components/submit-button/submit-button.component';
import { InfoIconTooltipSetComponent } from '../login/components/info-icon-tooltip-set/info-icon-tooltip-set.component';
import { InfoTooltipComponent } from '../login/components/info-tooltip/info-tooltip.component';
import { FormInputComponent } from '../login/components/form-input/form-input.component';
import { MessageComponent } from '../login/components/message/message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordValidatorComponent } from "../login/components/password-validator/password-validator.component";
import { ZilPasswordInputComponent } from '../login/components/zil-password-input/zil-password-input.component';
import { RequestsService } from '../login/service/requests.service';


@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordValidatorComponent,
    ZilPasswordInputComponent
],
  declarations: [
    MaintenanceBannerComponent,
    NotyComponent,
    CaptchaComponent,
    DialogTemplateComponent,
    DialogContentComponent,
    DialogFooterComponent,
    PlatformSelectDialogComponent,
    RegularForgotPasswordComponent,
    RegularBackLinkComponent,
    SubmitButtonComponent,
    InfoTooltipComponent,
    InfoIconTooltipSetComponent,
    FormInputComponent,
    MessageComponent,
  ],
  providers: [
    CommonService,
    AnalyticsService,
    RequestsService
  ],
  exports: [
    NotyComponent,
    CaptchaComponent,
    DialogTemplateComponent,
    MaintenanceBannerComponent,
    RegularForgotPasswordComponent,
    RegularBackLinkComponent,
    SubmitButtonComponent,
    InfoTooltipComponent,
    InfoIconTooltipSetComponent,
    FormInputComponent,
    MessageComponent
  ]
})
export class ZiCommonModule {
}
