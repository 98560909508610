<div class="requirement">
  @if (isPwLengthCorrect()) {
    <img src="/assets/images/zil-setup-password/checkmark-success.svg" alt="checkmark checked" />
  } @else {
    <img src="/assets/images/zil-setup-password/checkmark-fail.svg" alt="checkmark unchecked" />
  }
  <span class="requirement__text" [ngClass]="{'requirement__text_success': isPwLengthCorrect()}">8-20 char.</span>
</div>
<div class="requirement">
  @if (isPwIncludeLowerCaseLetter()) {
    <img src="/assets/images/zil-setup-password/checkmark-success.svg" alt="checkmark checked" />
  } @else {
    <img src="/assets/images/zil-setup-password/checkmark-fail.svg" alt="checkmark unchecked" />
  }
  <span class="requirement__text" [ngClass]="{'requirement__text_success': isPwIncludeLowerCaseLetter()}">Lowercase</span>
</div>
<div class="requirement">
  @if (isPwIncludeNumber()) {
    <img src="/assets/images/zil-setup-password/checkmark-success.svg" alt="checkmark checked" />
  } @else {
    <img src="/assets/images/zil-setup-password/checkmark-fail.svg" alt="checkmark unchecked" />
  }
  <span class="requirement__text" [ngClass]="{'requirement__text_success': isPwIncludeNumber()}">Number</span>
</div>
<div class="requirement">
  @if (isPwIncludeSymbol()) {
    <img src="/assets/images/zil-setup-password/checkmark-success.svg" alt="checkmark checked" />
  } @else {
    <img src="/assets/images/zil-setup-password/checkmark-fail.svg" alt="checkmark unchecked" />
  }
  <span class="requirement__text" [ngClass]="{'requirement__text_success': isPwIncludeSymbol()}">Symbol</span>
</div>
